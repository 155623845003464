@charset "utf-8";

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body{
	font: 12px/1.5 "Meiryo","メイリオ";
	color:#333;
	-webkit-text-size-adjust: none;
	background:#FAAF3B; /* 背景 #BC8F5C */
  overflow-x: hidden;  /* 横スクロール禁止 */
}

/* オブジェクトレスポンシブ用
------------------------------------------------------------*/
/* オブジェクト設定 */
embed,iframe,object {
	max-width: 100%;
}
 
/* Youtube
------------------------------------------------------------*/
.youtube iframe{
  width: 100%;
}

/* MyCardのタイトル書式
------------------------------------------------------------*/
#info h1{
  padding:5px 0 10px;
  color: #515151;
  font-size:150%;
  /* font-weight:bold; */
  text-align:left;
}

#info h2{
  margin:1px 0;
  padding-bottom:2px;
  font-size:130%;
  font-weight:normal;
  color: #333;
  font-weight:bold;
}


/* sidebar
------------------------------------------------------------*/
#sidebar a:not(.typo_gy){
  margin:0;
  padding:0;
  text-decoration:none;
  outline:0;
  vertical-align:baseline;
  background:transparent;
  font-size:100%;
  color:#313131;
}

/* MyCard
*****************************************************/
.typo_gy {
  padding:0;
}

.typo_gy table {
  padding:0;
  border: 1px #ccc solid;
  border-collapse: collapse;
  border-spacing: 0;
  margin:10px 10px 10px 20px;
}

.typo_gy table th{
  padding:10px;
  border: #ccc solid;
  border-width: 0 0 1px 1px;
  background:#faf1df;
}

.typo_gy table td{
  padding:10px;
  border: 1px #ccc solid;
  border-width: 0 0 1px 1px;
  background:#fff;
}

.typo_gy dt{font-weight:bold;}

.typo_gy dd{padding-bottom:10px;}

.typo_gy img{max-width:100%;height:auto;}

.typo_gy li:last-child{border:0;}
.typo_gy li:last-child{border:0;}

.typo_gy img{
  /* float:left; */
  margin:0 5px 5px 10px;
}

.typo_gy h3{
  margin:0 0 10px 10px;
  font-size:14px;
  font-weight:normal;
  color:#333;
  border:0;
}

.typo_gy h3{
	margin:0 0 10px 10px;
	font-size:14px;
	font-weight:normal;
	color:#333;
	border:0;
}

tbody {
	padding:0;
}


.alignright{float:right;}
.alignleft{float:left;}

input[type="text"]{
	width:auto
}


/* フッター
*****************************************************/
#copyright{
  clear:both;
  padding:10px 0 37px;
  text-align:center;
  color:#000;
  font-size:10px;
}


/* page navigation
------------------------------------------------------------*/
.pagenav{
  clear:both;
  width: 100%;
  height: 30px;
  margin: 5px 0 20px;
}

.prev{float:left}

.next{float:right;}

#pageLinks{
  clear:both;
  color:#4f4d4d;
  text-align:center;
}

/* bootstrap pagination */
ul.pagination li {
  margin: 0;
  padding: 10;
  border-bottom: none
}


nav#mainNav ul li.current-menu-item a, nav#mainNav ul li a:hover span, nav#mainNav ul li a:hover, nav#mainNav ul li.current-menu-parent a{
  color:#4c4c4c;
}